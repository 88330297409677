import { action, observable } from 'mobx';
import EventSummary from './../services/events/dto/eventSummary'
import eventService from './../services/events/eventService';
class EventStore {
  @observable landingPageCardEvents: EventSummary[] = [];

  @action
  async getLandingPageCardEvents() {
    let result = await eventService.getLandingPageCardEvents();
    this.landingPageCardEvents = result;
  }
}

export default EventStore;