import http from '../httpService'
import EventCategory from './dto/eventSummary'
import AutocompleteModel from '../advancedSearch/dto/autocompleteModel';
import AddToCartModel from './dto/AddToCartModel';
import CheckoutModel from './dto/CheckoutModel';
import ConfirmCartModel from './dto/ConfirmCartModel';
import OrderPostedResponse from './dto/OrderPostedResponse';

class EventService {
    public async getFilteredEvents(filters: AutocompleteModel[], page:number, quantityPerPage:number, fromDate:Date, toDate:Date): Promise<EventCategory[]> {
      let result = await http.post('api/Events/AdvancedSearch',{filters:filters,page:page,quantityPerPage:quantityPerPage, fromDate:fromDate, toDate:toDate});
      return result.data;
    }
  public async getLandingPageCardEvents(): Promise<EventCategory[]> {
    let result = await http.get('api/Events/LandingPageCardEvents');
    return result.data;
  }
  
  public async searchEvents(query:string,page:number, quantityPerPage:number): Promise<EventCategory[]> {
    let result = await http.get('api/Events/Search',{params:{query:query,page:page,quantityPerPage:quantityPerPage}});
    return result.data;
  }

  public async getEventsForCategory(categoryName:string,page:number, quantityPerPage:number): Promise<EventCategory[]> {
    let result = await http.get('api/Events/EventsForCategory',{params:{categoryName:categoryName,page:page,quantityPerPage:quantityPerPage}});
    return result.data;
  }

  public async getEventsForVenue(venueName:string,page:number, quantityPerPage:number): Promise<EventCategory[]> {
    let result = await http.get('api/Events/EventsForVenue',{params:{venueName:venueName,page:page,quantityPerPage:quantityPerPage}});
    return result.data;
  }

  public async getEventsForPerformer(performerName:string,page:number, quantityPerPage:number): Promise<EventCategory[]> {
    let result = await http.get('api/Events/EventsForPerformer',{params:{performerName:performerName,page:page,quantityPerPage:quantityPerPage}});
    return result.data;
  }

  public async getTicketsForEvent(eventName:string,id:number): Promise<AddToCartModel> {
    let result = await http.get('api/Events/GetTicketsForEvent',{params:{eventName:eventName, id:id}});
    return result.data;
  }

  public async getCheckoutModel(ticketGroupId:number,our:boolean): Promise<CheckoutModel> {
    let result = await http.get('api/Events/GetTicketForCheckout',{params:{ticketGroupId:ticketGroupId,our:our}});
    return result.data;
  }

  public async postOrder(model:ConfirmCartModel): Promise<OrderPostedResponse> {
    let result = await http.post('api/Events/PostRequest',model);
    return result.data;
  }
}

export default new EventService();