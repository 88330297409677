import './Navigation.less'
import React, { Component, ChangeEvent, RefObject } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Layout, Button, Drawer, Input, Dropdown, Spin } from 'antd';
import PthIcon from '../Icons/index'
import { observer, inject } from 'mobx-react';
import windowSize, { WindowSizeProps } from 'react-window-size';
import SessionStore from '../../stores/SessionStore'
import EventCategoryStore from '../../stores/EventCategoriesStore'
import logo from '../../assets/images/PTHLogo.png'


const { SubMenu } = Menu;
const WAIT_INTERVAL = 300;


export interface NavigationProps extends WindowSizeProps {
    sessionStore?: SessionStore;
    eventCategoryStore?: EventCategoryStore;
}

export interface NavigationPropsExternal extends WindowSizeProps {
    sessionStore: SessionStore;
    eventCategoryStore: EventCategoryStore;
}

@inject('sessionStore', 'eventCategoryStore')
@observer
class Navigation extends Component<NavigationProps, NavigationPropsExternal, {}> {

    private timer: any;
    constructor(props: NavigationProps) {
        super(props);
        this.timer = null;
        this.toogleSearchMobile = this.toogleSearchMobile.bind(this);
        this.searchOnChange = this.searchOnChange.bind(this);
        this.triggerChange = this.triggerChange.bind(this);
        this.desktopSearchFocusCapture = this.desktopSearchFocusCapture.bind(this);
        this.desktopSearchLostFocus = this.desktopSearchLostFocus.bind(this);
        this.mobileSearchFocusCapture = this.mobileSearchFocusCapture.bind(this);
        this.mobileSearchLostFocus = this.mobileSearchLostFocus.bind(this);
        this.hideDesktopSearch = this.hideDesktopSearch.bind(this);
        this.hideMobileSearch = this.hideMobileSearch.bind(this);
        this.emptyCart = this.emptyCart.bind(this);
    }

    async componentDidMount() {
        await this.props.eventCategoryStore!.getFootballEventCategories();
        await this.props.eventCategoryStore!.getTEVOPinnedCategories();
        await this.props.sessionStore!.readCheckoutModel();
    }


    componentWillMount() {
        document.body.addEventListener('click', this.handleClick);
    }

    componentWillUnmount() {
        // remember to remove all events to avoid memory leaks
        document.body.removeEventListener('click', this.handleClick);
    }

    handleClick(event: MouseEvent) {
        // console.log(event);
    }

    async searchOnChange(event: ChangeEvent<HTMLInputElement>) {
        clearTimeout(this.timer);
        if (event.target.value.length == 0) {
            this.props.sessionStore!.isSearching = false;
            this.props.sessionStore!.searchQuery = event.target.value;
            this.props.sessionStore!.isDesktopSearchResultOpen = false;
            this.props.sessionStore!.isMobileSearchResultOpen = false;
            return;
        }
        this.props.sessionStore!.isSearching = true;
        this.props.sessionStore!.searchQuery = event.target.value;
        this.props.sessionStore!.isDesktopSearchResultOpen = false;
        this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL);
    }

    triggerChange() {
        this.props.sessionStore!.search();
    }

    async desktopSearchFocusCapture() {
        if (this.props.sessionStore!.searchQuery.length > 0) {
            this.props.sessionStore!.isDesktopSearchResultOpen = true;
        }
    }

    async desktopSearchLostFocus() {
        setTimeout(this.hideDesktopSearch, 400);
    }

    hideDesktopSearch() {
        this.props.sessionStore!.isDesktopSearchResultOpen = false;
    }

    async mobileSearchFocusCapture() {
        if (this.props.sessionStore!.searchQuery.length > 0) {
            this.props.sessionStore!.isMobileSearchResultOpen = true;
        }
    }
    async mobileSearchLostFocus() {
        setTimeout(this.hideMobileSearch, 400);
    }
    hideMobileSearch() {
        this.props.sessionStore!.isMobileSearchResultOpen = false;
    }


    toogleSearchMobile() {
        this.props.sessionStore!.isMobileSearchOpen = !this.props.sessionStore!.isMobileSearchOpen;
        if (!this.props.sessionStore!.isMobileSearchOpen)
            this.props.sessionStore!.isMobileSearchResultOpen = false;
    }

    emptyCart() {
        this.props.sessionStore!.emptyCart();
    }

    renderNavLinks = () => {
        let signedInUserId = true;
        if (signedInUserId) {
            return [
              
                <SubMenu key="sub1"
                    title={
                        <span>
                            <span>FOOTBALL</span>
                        </span>
                    }
                    >
                    {this.props.eventCategoryStore!.footballEventCategories.map(item =>
                        <Menu.Item key={item.eventCategoryId}>
                            <Link to={{ pathname: '/buy-tickets-for-category/' + item.eventCategoryName.trim().split(' ').join('-'), state: { type: 'category', value: item.eventCategoryName } }}>
                                {item.eventCategoryName}
                            </Link>
                        </Menu.Item>)}
                        <Menu.Item>
                            <a href='https://www.premiumtickethub.com/premier-league-packages' >Premier League Packages</a>
                        </Menu.Item>
                </SubMenu>,
                 
                <SubMenu
                key="sub2"
                    title={
                        <span>
                            <span>OTHER EVENTS</span>
                        </span>
                    }
                >
                    {this.props.eventCategoryStore!.TEVOPinnedCategories.map(item =>
                        <Menu.Item key={item.id}>
                            <Link to={{ pathname: '/buy-tickets-for-category/' + item.name.split(' ').join('-'), state: { type: 'category', value: item.name } }}>
                                {item.name}
                            </Link>
                        </Menu.Item>)}
                </SubMenu>,
            ]
        } else {
            return (
                <Menu.Item key={3}><Link to="\Sign in"><Button ghost>Sign in</Button></Link></Menu.Item>
            )
        }
    }

    //User Account Shopping Cart
    renderUserLinks = () => {
        return (
            [<Menu.Item key="2" style={{ float: 'left', display: 'flex', justifyContent: "space-around", alignItems: 'center', width: this.props.sessionStore!.isMobileMenu ? "115px" : "145px", paddingLeft: 0, paddingRight: 0 }}>
                {this.props.sessionStore!.isMobileMenu ?
                    <img className="" style={{ marginTop: 10, height: 50 }} src={logo} /> :
                    <img className="ant-menu-item" height={65} src={logo} style={{ paddingLeft: 0, paddingRight: 0 }} />
                }
                <Link to="/"></Link>
            </Menu.Item>,
            <Menu.Item key="3" style={{ float: 'right', display: 'flex', justifyContent: "space-around", alignItems: 'center', width: "60px" }}>
                {
                    this.props.sessionStore!.isMobileMenu &&
                    <a style={{ height: 25, position: 'relative' }} onClick={() => this.props.sessionStore!.toggleMobileMenuOpen()}>
                        <PthIcon type="icon-menu" height="25" primaryColor="lightgray" secondaryColor="lightgray" />
                    </a>
                }
            </Menu.Item>,
            <SubMenu key="4" style={{ float: 'right', display: 'flex', justifyContent: "space-around", alignItems: 'center', width: "50px" }}
                title={<a style={{ height: 25, position: 'relative' }}>
                    <PthIcon type="icon-shopping-cart" height="25" primaryColor="lightgray" secondaryColor="lightgray" />
                    <span className="badge">{this.props.sessionStore!.cartCount}</span>
                </a>}>
                {this.props.sessionStore!.cartCount > 0 ? <Menu.Item key="sub2g11a"><Link to="/checkout">{this.props.sessionStore!.cartCount}x {this.props.sessionStore!.checkoutModel.eventName}</Link></Menu.Item> : ''}
                {this.props.sessionStore!.cartCount > 0 ? <Menu.Item key="sub2g11a"><Link to="/checkout">Checkout</Link></Menu.Item> : ''}
                {this.props.sessionStore!.cartCount > 0 ? <Menu.Item key="sub2g12a" onClick={this.emptyCart}>Empty cart</Menu.Item> : ''}

            </SubMenu>,
            // <SubMenu className="remove-padding" style={{ float: 'right', display: 'flex', justifyContent: "space-around", alignItems: 'center', width: "50px" }}
            //     key="sub2a"
            //     title={
            //         <a style={{ height: 25, position: 'relative' }}><PthIcon type="icon-user-circle" height="25" primaryColor="lightgray" secondaryColor="grey" /></a>
            //     }
            // >
            //     <Menu.Item key="sub2g11a">SIGN UP</Menu.Item>
            //     <Menu.Item key="sub2g12a">LOG IN</Menu.Item>
            // </SubMenu>,
            this.props.windowWidth! < 1160 ? this.renderMobileSearchBarMenu() : this.renderDesktopSearchBar()
            ]
        )
    }

    renderDesktopNavbar = () => {
        this.props.sessionStore!.setIsMobileMenu(this.props.windowWidth!);
        return this.renderNavLinks();
    }

    renderMobileNavbar = () => {
        this.props.sessionStore!.setIsMobileMenu(this.props.windowWidth!);
        return '';
    }

    renderDesktopSearchBar = () => {
        return <Menu.Item key="21" ref="searchDesktop" style={{ float: 'right', display: 'flex', justifyContent: "space-around", alignItems: 'center', width: "320px" }}>
            <Input style={{ top: 15, height: 25, position: 'relative' }} onChange={this.searchOnChange} onFocusCapture={this.desktopSearchFocusCapture} onBlur={this.desktopSearchLostFocus}
                className="desktop-search"
                placeholder="Search by keywords"
                suffix={
                    <a style={{ position: "relative", top: '3px' }}>
                        {this.props.sessionStore!.isSearching ? <Spin style={{ marginTop: '4px' }} /> : <PthIcon type="icon-search" height="20" primaryColor="#4a565f" secondaryColor="#a09e96" />}
                    </a>
                }
            />

            {this.props.sessionStore!.isDesktopSearchResultOpen ? <span className="desktop-search-result-container">
                {this.props.sessionStore!.searchResult.length == 0 ? (
                    <Link className="search-result-item" to="/advanced-search">
                        <h4>No results for "{this.props.sessionStore!.searchQuery}"</h4>
                    </Link>)
                    // <Link className="search-result-item" to="/advanced-search">No results for (keyword)</Link>)
                    : this.props.sessionStore!.searchResult.map(item =>
                        <Link className="search-result-item" to={{ pathname: '/buy-tickets-for/' + item.eventName.split(' ').join('-') + '-' + item.eventId, state: { id: item.eventId, value: item.eventName } }}>
                            <h4>{item.eventName}</h4>
                            <h5 className="mark-last">{new Date(item.eventDate).toLocaleDateString([], { weekday: 'long' })}, {new Date(item.eventDate).toLocaleDateString([], { month: 'short' })} {new Date(item.eventDate).getDate().toString()} </h5>
                        </Link>)
                }
                <Link className="search-result-item" to="/advanced-search">Advanced search</Link>
            </span> : ''}
        </Menu.Item>;
    }

    renderMobileSearchBarMenu = () => {

        return <Menu.Item style={{ float: 'right', display: 'flex', justifyContent: "space-around", alignItems: 'center', width: "50px" }}>
            <a style={{ height: 25, position: 'relative' }} onClick={this.toogleSearchMobile}>
                <PthIcon type="icon-search" height="25" primaryColor="#39434a" secondaryColor="#a09e96" />
            </a>
        </Menu.Item>
    }

    renderMobileSearchBar = () => {
        return !this.props.sessionStore!.isMobileSearchOpen || this.props.windowWidth! >= 1160 ? "" : <div className="mobile-search-bar-div">
            <Input id="mobile-search-bar" style={{ height: 25, position: 'relative' }} onChange={this.searchOnChange} onFocusCapture={this.mobileSearchFocusCapture} onBlur={this.mobileSearchLostFocus}
                className="mobile-search-input"
                placeholder="Search by keywords"
                suffix={
                    <a style={{ position: "relative", top: '3px' }}>
                        {this.props.sessionStore!.isSearching ? <Spin style={{ marginTop: '4px' }} /> : <PthIcon type="icon-search" height="20" primaryColor="#4a565f" secondaryColor="#a09e96" />}
                    </a>
                }
            />
            {this.props.sessionStore!.isMobileSearchResultOpen ? <div className="mobile-search-result-container">
                {this.props.sessionStore!.searchResult.length == 0 ?
                    <Link className="search-result-item" to="/advanced-search">
                        <h4>No results for "{this.props.sessionStore!.searchQuery}"</h4>
                    </Link> :
                    // <Link className="search-result-item" to="/advanced-search">No results for ({this.props.sessionStore!.searchQuery})</Link> :
                    this.props.sessionStore!.searchResult.map(item =>
                        <Link className="search-result-item" to={{ pathname: '/buy-tickets-for/' + item.eventName.split(' ').join('-') + '-' + item.eventId, state: { id: item.eventId, value: item.eventName } }}>
                            <h4>{item.eventName}</h4>
                            <h5>{new Date(item.eventDate).toLocaleDateString([], { weekday: 'long' })}, {new Date(item.eventDate).toLocaleDateString([], { month: 'short' })} {new Date(item.eventDate).getDate().toString()} </h5>
                        </Link>)
                }
                <Link className="search-result-item" to="/advanced-search">Advanced search</Link>
            </div> : ''}
        </div>;
    }

    public render() {
        let { toggleMobileMenuOpen, mobileMenuOpen, authUser, tab } = this.props.sessionStore!;
        return (
            <div>
                <Drawer
                    title={<img className="ant-menu-item" height={65} src={logo} />}
                    placement='left'
                    closable={false}
                    onClose={() => toggleMobileMenuOpen()}
                    visible={mobileMenuOpen}
                >
                    <Menu className='left-menu' mode="inline" onClick={() => toggleMobileMenuOpen()}>{this.renderNavLinks()}</Menu>
                </Drawer>
                <Layout.Header style={{ zIndex: 1, width: '100%', padding: this.props.sessionStore!.isMobileMenu ? '0' : '0 20px', textAlign: 'center' }}>
                    <Menu
                        theme="dark"
                        mode={"horizontal"}
                        style={{ lineHeight: '64px' }}
                        selectedKeys={[tab.toString()]}
                    >
                        {this.props.sessionStore!.isMobileMenu ? this.renderMobileNavbar() : this.renderDesktopNavbar()}
                        {authUser && this.renderUserLinks()}
                    </Menu>
                </Layout.Header>
                {this.renderMobileSearchBar()}
            </div>
        )
    }
}
export default windowSize(Navigation);