import { action, observable } from 'mobx';
import Filter from '../components/Filter';
import autoCompleteService from '../services/advancedSearch/autocompleteService'
import AutocompleteModel from '../services/advancedSearch/dto/autocompleteModel';
import { number, array } from 'prop-types';
import moment, { Moment } from 'moment';
export enum FilterType {
    general,
    event
}

class FilterStore {
    @observable collapsed: boolean = false;
    @observable fixedCategory: boolean = false;
    @observable fixedVenue: boolean = false;
    @observable fixedPerformer: boolean = false;
    @observable fixedEvent: boolean = false;
    @observable dates: [string, string] = ["", ""];
    @observable venueAutoComplete: AutocompleteModel[] = [];
    @observable performerAutoComplete: AutocompleteModel[] = [];
    @observable categoryAutoComplete: AutocompleteModel[] = [];
    @observable eventAutoComplete: AutocompleteModel[] = [];

    @observable performerQuery: string = '';
    @observable categoryQuery: string = '';
    @observable eventQuery: string = '';
    @observable venueQuery: string = '';

    @observable performerFilter: AutocompleteModel = new AutocompleteModel();
    @observable categoryFilter: AutocompleteModel = new AutocompleteModel();
    @observable eventFilter: AutocompleteModel = new AutocompleteModel();
    @observable venueFilter: AutocompleteModel = new AutocompleteModel();

    @observable performerSelectedIndex: number | undefined = undefined;
    @observable categorySelectedIndex: number | undefined = undefined;
    @observable eventSelectedIndex: number | undefined = undefined;
    @observable venueSelectedIndex: number | undefined = undefined;


    //new----------------
    @observable filters: AutocompleteModel[] = [];
    @observable selectedFilterIndex: number | undefined = undefined;
    @observable showClearAll: boolean = true;
    @observable datesMoment: [Moment, Moment]|undefined = undefined;


    @action
    async toogleCollapse() {
        this.collapsed = !this.collapsed;
    }

    @action
    startCleaned() {
        this.collapsed = false;
        this.fixedCategory = false;
        this.fixedVenue = false;
        this.fixedPerformer = false;
        this.fixedEvent = false;
        this.dates = ["", ""];
        this.categoryAutoComplete = [];
        this.venueAutoComplete = [];
        this.performerAutoComplete = [];
        this.eventAutoComplete = [];
        this.filters = [];
        this.selectedFilterIndex = undefined;
    }


    @action
    async setDates(dates: [string, string]) {
        this.dates = dates;
        this.datesMoment = [moment(dates[0]),moment(dates[1])];
    }

    @action
    async startCategory(category: string) {
        this.fixedCategory = true;
        this.categoryQuery = category;
        await this.setQueryCategory(category);
        await this.setFilterCategory(0);
        
    }

    @action
    async startVenue(venue: string) {
        this.fixedVenue = true;
        this.venueQuery = venue;
    }

    @action
    async startEvent(event: string) {
        this.fixedEvent = true;
        this.eventQuery = event;
    }

    @action
    async startPerformer(performer: string) {
        this.fixedPerformer = true;
        this.performerQuery = performer;
    }

    @action
    async startPerformerAndLoad(performer: string) {
        this.fixedPerformer = true;
        this.performerQuery = performer;
        await this.setQueryPerformer(performer);
        return this.setFilterPerformerAndReload(0);

    
    }

    @action
    async setQueryPerformer(value: string) {
        if (value.length === 0) {
            this.performerAutoComplete = [];
            return;
        }
        let result = await autoCompleteService.getPerformerAutocomplete(value);
        this.performerAutoComplete = await result;
    }


    unmount() {
        this.collapsed = false;
        this.fixedCategory = false;
        this.fixedVenue = false;
        this.fixedPerformer = false;
        this.fixedEvent = false;
        this.dates = ["", ""];
        this.venueAutoComplete = [];
        this.performerAutoComplete = [];
        this.categoryAutoComplete = [];
        this.eventAutoComplete = [];

        this.performerQuery = '';
        this.categoryQuery = '';
        this.eventQuery = '';
        this.venueQuery = '';

        this.performerFilter = new AutocompleteModel();
        this.categoryFilter = new AutocompleteModel();
        this.eventFilter = new AutocompleteModel();
        this.venueFilter = new AutocompleteModel();

        this.performerSelectedIndex = undefined;
        this.categorySelectedIndex = undefined;
        this.eventSelectedIndex = undefined;
        this.venueSelectedIndex = undefined;
        //new----------------
        this.filters = [];
        this.selectedFilterIndex = undefined;
        this.showClearAll = true;

    }

    async setQueryVenue(value: string) {
        if (value.length === 0) {
            this.venueAutoComplete = [];
            return;
        }
        let result = await autoCompleteService.getVenueAutocomplete(value);
        this.venueAutoComplete = result;
    }

    async setQueryEvent(value: string) {
        if (value.length === 0) {
            this.eventAutoComplete = [];
            return;
        }
        let result = await autoCompleteService.getEventAutocomplete(value);
        this.eventAutoComplete = result;
    }

    async setQueryCategory(value: string) {
        if (value.length === 0) {
            this.categoryAutoComplete = [];
            return;
        }
        let result = await autoCompleteService.getCategoryAutocomplete(value);
        this.categoryAutoComplete = result;
    }

    @action setFilterPerformer(index: number) {
        this.performerSelectedIndex = index;
        this.performerFilter = this.performerAutoComplete[index];
        this.filters.push(this.performerFilter);
        console.log("recien ahora se triggerea esto re raro",this.filters)
    }
    @action setFilterPerformerAndReload(index: number) {
        this.performerSelectedIndex = index;
        this.performerFilter = this.performerAutoComplete[index];
        this.performerFilter.type= 'performer'
        this.filters.push(this.performerFilter);

        return this.filters
        // console.log("recien ahora se triggerea esto re raro",this.filters)
    }

    @action setFilterVenue(index: number) {
        this.venueSelectedIndex = index;
        this.venueFilter = this.venueAutoComplete[index];
        this.filters.push(this.venueFilter);
    }

    @action setFilterEvent(index: number) {
        this.eventSelectedIndex = index;
        this.eventFilter = this.eventAutoComplete[index];
        this.filters.push(this.eventFilter);
    }

    @action setFilterCategory(index: number) {
        this.categorySelectedIndex = index;
        this.categoryFilter = this.categoryAutoComplete[index];
        this.categoryFilter.type="category"
        this.filters.push(this.categoryFilter);
    }

    @action clearFilterPerformer() {
        this.performerFilter = new AutocompleteModel();
        this.performerQuery = '';
        this.performerAutoComplete = [];
        this.performerSelectedIndex = undefined;
        this.filters = this.filters.slice(0, this.filters.length - 1);
    }

    @action clearFilterEvent() {
        this.eventFilter = new AutocompleteModel();
        this.eventQuery = '';
        this.eventAutoComplete = [];
        this.eventSelectedIndex = undefined;
        this.filters = this.filters.slice(0, this.filters.length - 1);
    }

    @action clearFilterCategory() {
        if (this.fixedCategory) return;
        this.categoryFilter = new AutocompleteModel();
        this.categoryQuery = '';
        this.categoryAutoComplete = [];
        this.categorySelectedIndex = undefined;
        this.filters = this.filters.slice(0, this.filters.length - 1);
    }

    @action clearFilterVenue() {
        this.venueFilter = new AutocompleteModel();
        this.venueQuery = '';
        this.venueAutoComplete = [];
        this.venueSelectedIndex = undefined;
        this.filters = this.filters.slice(0, this.filters.length - 1);
    }

    getCurrentFilters(): AutocompleteModel[] {
        var filters = [];
        if (this.performerFilter.name && this.performerFilter.name.length > 0) {
            this.performerFilter.type = 'performer';
            filters.push(this.performerFilter);
        }

        if (this.eventFilter.name && this.eventFilter.name.length > 0) {
            this.eventFilter.type = 'event';
            filters.push(this.eventFilter);
        }
        if (this.categoryFilter.name && this.categoryFilter.name.length > 0) {
            this.categoryFilter.type = 'category';
            filters.push(this.categoryFilter);
        }
        if (this.venueFilter.name && this.venueFilter.name.length > 0) {
            this.venueFilter.type = 'venue';
            filters.push(this.venueFilter);
        }
        return filters;
    }

    @action
    clearAll() {
        this.dates = ["", ""];
        this.datesMoment = undefined;
        if (!this.fixedCategory) {
            this.clearFilterCategory();
        }
        if (!this.fixedEvent) {
            this.clearFilterEvent();
        }
        if (!this.fixedPerformer) {
            this.clearFilterPerformer();
        }
        if (!this.fixedVenue) {
            this.clearFilterVenue();
        }
    }
}
export default FilterStore;