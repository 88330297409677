import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'mobx-react';
import SessionStore from './stores/SessionStore';
import EventCategoryStore from './stores/EventCategoriesStore';
import EventStore from './stores/EventsStore';
import HomePageStore from './stores/HomePageStore';
import UserRequestStore from './stores/UserRequestStore';
import RedirectRuleStore from './stores/RedirectRuleStore';
import EventListStore from './stores/SceneStores/EventListStore';
import FilterStore from './stores/FilterStore';
import BookingStore from './stores/BookingStore';
import ScrollToTop from './components/ScrollToTop';

const store = new SessionStore();
const eventCategoryStore = new EventCategoryStore();
const eventStore = new EventStore();
const homePageStore = new HomePageStore();
const userRequestStore = new UserRequestStore();
const redirectRuleStore = new RedirectRuleStore();
const eventListStore = new EventListStore();
const filterStore = new FilterStore();
const bookingStore = new BookingStore();

ReactDOM.render(
    <Provider sessionStore={store} eventCategoryStore={eventCategoryStore} eventStore={eventStore} homePageStore={homePageStore} userRequestStore={userRequestStore} redirectRuleStore={redirectRuleStore} eventListStore={eventListStore} filterStore={filterStore} bookingStore={bookingStore}>
        <BrowserRouter>
            <ScrollToTop />
            <App />
        </BrowserRouter>
    </Provider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
