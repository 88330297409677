import './AppLayout.less'
import * as React from 'react'
import Navigation from './Navigation';
import Footer from './Footer';
import { Layout, BackTop } from 'antd';
import { appRouters } from '../Router/router.config';
import { Redirect, Route, Switch } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { WindowSizeProps } from 'react-window-size';
import SessionStore from '../../stores/SessionStore';
import RedirectRuleStore from '../../stores/RedirectRuleStore';
import LoadableComponent from '../Loadable';

export interface LayoutProps extends WindowSizeProps {
    sessionStore?: SessionStore;
    redirectRuleStore?: RedirectRuleStore;
}

@inject('sessionStore', "redirectRuleStore")
@observer
class AppLayout extends React.Component<LayoutProps>{

    public componentDidMount() {
        this.props.redirectRuleStore!.getRedirectRules();
    }
    public render() {
        if (this.props.redirectRuleStore!.rules.length === 0)
            return "";
        return <Layout>
            <p className="google-required-message" style={{backgroundColor:'#c6ced3', color:'#323b41'}}>Your trusted Ticket Concierge since 2008. {this.props.sessionStore!.isMobileMenu ? <br /> : ''} Prices displayed may be above or below the face value of the ticket.</p>
            <Navigation />
            <BackTop/>
            <div>
                <Switch>
                    <Redirect from="/terms" to="/terms-and-conditions" />
                    <Route key={1} path={'/welcome'} component={LoadableComponent(() => import('../../scenes/Home'))}></Route>
                    <Route key={2} path={'/faqs'}  component={LoadableComponent(() => import('../../scenes/Faqs'))}></Route>
                    <Route key={3} path={'/buy-tickets-for-category'} component={LoadableComponent(() => import('../../scenes/EventsList'))}></Route>
                    <Route key={4} path={'/buy-tickets-for-venue'} component={LoadableComponent(() => import('../../scenes/EventsList'))}></Route>
                    <Route key={5} path={'/buy-tickets-for-performer'} component={LoadableComponent(() => import('../../scenes/EventsList'))}></Route>
                    <Route key={6} path={'/advanced-search'} component={LoadableComponent(() => import('../../scenes/AdvancedSearch'))}></Route>
                    <Route key={7} path={'/buy-tickets-for'} component={LoadableComponent(() => import('../../scenes/Booking'))}></Route>
                    <Route key={8} path={'/checkout'} component={LoadableComponent(() => import('../../scenes/Checkout'))}></Route>
                    <Route key={9} path={'/terms-and-conditions'} component={LoadableComponent(() => import('../../scenes/TermsAndConditions'))}></Route>
                    <Route key={10} path={'/privacy-policy'} component={LoadableComponent(() => import('../../scenes/PrivacyPolicy'))}></Route>
                    <Route key={11} path={'/about-us'} component={LoadableComponent(() => import('../../scenes/AboutUs'))}></Route>
                    <Route key={12} path={'/result'} component={LoadableComponent(() => import('../../scenes/Checkout-Result'))}></Route>
                    <Route key={13} path={'/articles'} component={LoadableComponent(() => import('../../scenes/Articles'))}></Route>
                    <Route key={14} path={'/top-5-venues-premier-league'} component={LoadableComponent(() => import('../../scenes/First-Article'))}></Route>
                    <Route key={15} path={'/top-5-questions-premier-league'} component={LoadableComponent(() => import('../../scenes/Second-Article'))}></Route>
                    <Route key={16} path={'/could-liverpool-win-premier-and-champions-league'} component={LoadableComponent(() =>import('../../scenes/Third-Article'))}></Route>
                    {this.props.redirectRuleStore!.rules.map((route: any, index: any) => (
                        <Redirect exact key={25 + index} from={"/" + route.from} to={"/" + route.to} />
                    ))}
                    <Redirect from="/" to="/welcome"/>
                </Switch>
            </div>
            <Footer />
        </Layout>
    };

}
export default AppLayout;