export default class ConfirmCartModel {
    ticketGroupId?:number;
    quantity?:number;
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    addressLine1?: string;
    addressLine2?: string;
    city?: string;
    state?: string;
    zipcode?: string;
    country?: string;
    info?: string;
    paymentNonce?: string;
    ccnumber?: string;
    cvv?: string;
    month?: string;
    year?: string;
    our?: boolean;
    pricePerTicket?: number;
    teInfo?: string;
    ip?: string;
}