import * as React from 'react';

import { Route, Switch ,Redirect} from 'react-router-dom';
import AppLayout from '../Layout/AppLayout';

const Router = () => {
    return (
      <Switch>
        <Route exact path="/landing">
              <Redirect push to={"/home.html"} />
        </Route> 
        
        <Route path="/" render={(props: any) => <AppLayout {...props} />} />
      </Switch>
    );
  };
export default Router;