import http from '../httpService'
import LandingCover from './dto/landingCover'
import EventSummary from '../events/dto/eventSummary';
import SeasonalEventSettings from './dto/seasonalEventSettings';
import Banner from '../events/dto/Banner';

class EventService {
   
  public async getCovers(): Promise<LandingCover[]> {
    let result = await http.get('api/HomeConfig/GetCovers');
    return result.data;
  }

  public async getTrendingEvents(): Promise<EventSummary[]> {
    let result = await http.get('api/Events/TrendingEvents');
    return result.data;
  }
  public async getOtherEvents(): Promise<EventSummary[]> {
    let result = await http.get('api/Events/OtherEvents');
    return result.data;
    }
  public async getSeasonalEvents(): Promise<EventSummary[]> {
    let result = await http.get('api/Events/SeasonalEvents');
    return result.data;
  }

  public async getSeasonalEventSettings(): Promise<SeasonalEventSettings> {
    let result = await http.get('api/HomeConfig/GetSeasonalEventSettings');
    return result.data;
  }

  public async getBanners(): Promise<Banner[]> {
    let result = await http.get('api/Events/Banners');
    return result.data;
  }

  
}

export default new EventService();