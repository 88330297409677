import { action, observable } from 'mobx';
import RedirectRule from './../services/redirectRules/dto/redirectRule'
import redirectRuleService from './../services/redirectRules/redirectRuleService';
class RedirectRuleStore {
    @observable rules: RedirectRule[] = [];
    @action
    async getRedirectRules() {
        if (this.rules.length > 0)
            return;
        let result = await redirectRuleService.getAll();
        this.rules = result;
        if(this.rules.length===0)this.rules.push(new RedirectRule())
    }
}

export default RedirectRuleStore;