import { action, observable } from 'mobx';
import UserRequestResponse from './../services/userRequest/dto/userRequestResponse'
import userRequestService from './../services/userRequest/userRequestService';
class HomePageStore {
    @observable lastResponse: UserRequestResponse = new UserRequestResponse();
    @observable postingCustomRequest: boolean = false;

    @observable customRequestName:string="";
    @observable customRequestEmail:string="";
    @observable customRequestEventName:string="";
    @observable customRequestQuantity:string="";
    

    @action
    async postCustomRequest() {
        if (this.postingCustomRequest) return;
        this.postingCustomRequest = true;
        let result = await userRequestService.postCustomRequest(this.customRequestName, this.customRequestEmail, this.customRequestEventName, this.customRequestQuantity);
        this.lastResponse = result;
        this.postingCustomRequest = false;
    }
}

export default HomePageStore;