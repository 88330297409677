import './App.css';
import React from 'react';
import Router from './components/Router';
import SessionStore from './stores/SessionStore';
import { inject } from 'mobx-react';

export interface IAppProps {
  sessionStore: SessionStore;
}
@inject("sessionStore")
class App extends React.Component<any> {
  public render() {
    return <Router />;
  }
}





export default App;