import http from '../httpService'
import RedirectRule from './dto/redirectRule'

class RedirectRuleService {
  public async getAll(): Promise<RedirectRule[]> {
    let result = await http.get('api/RedirectRule/GetAll');
    return result.data;
  }
}

export default new RedirectRuleService();