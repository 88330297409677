import { action, observable } from 'mobx';
import EventSummary from './../../services/events/dto/eventSummary'
import eventService from './../../services/events/eventService';
import AutocompleteModel from '../../services/advancedSearch/dto/autocompleteModel';
import { toJS } from 'mobx';

class EventListStore {
    @observable currentPage: number = 1;
    @observable quantityPerPage: number = 10;
    @observable type: string = "";
    @observable value: string = "";
    @observable loadedEvents: EventSummary[] = [];
    @observable lastCount: number = 10;

    @observable filters: AutocompleteModel[] = [];
    @observable dateStrings: [string, string] = ['', ''];
    @observable loading: boolean = false;

    @action
    start(type: string, value: string) {
        this.type = type;
        this.value = value;
        this.loadedEvents = [];
        this.currentPage = 1;
        this.lastCount = 10;
        console.log("startr", this.filters)
    }

    @action
    async setFilters(filters: AutocompleteModel[], dateStrings: [string, string]) {
        this.filters = filters;
        this.dateStrings = dateStrings;
        await this.start('filters', '');
        await this.loadMore();
    }

    @action
    async loadMore() {
        console.log("loadmoreee")
        console.log(this.filters)
        console.log(this.type)
        this.loading=true;
        if (this.lastCount === 0)
            return;
        let result: EventSummary[] = [];

        if (this.type === 'category') {
            result = await eventService.getEventsForCategory(this.value, this.currentPage, this.quantityPerPage);
        }
        if (this.type === 'venue') {
            result = await eventService.getEventsForVenue(this.value, this.currentPage, this.quantityPerPage);
        }
        if (this.type === 'performer') {
            result = await eventService.getEventsForPerformer(this.value, this.currentPage, this.quantityPerPage);
        }
        if (this.type === 'filters') {
            var startDate = this.dateStrings[0].length > 0 ? new Date(this.dateStrings[0]) : new Date(1970, 1);
            var endDate = this.dateStrings[1].length > 0 ? new Date(this.dateStrings[1]) : new Date(2070, 1)
            console.log("aca estamos ahora ? ")
            result = await eventService.getFilteredEvents(toJS(this.filters), this.currentPage, this.quantityPerPage, startDate, endDate);
            console.log(this.filters)
            console.log(await result)
        }

        if (result.length > 0) {

            this.loadedEvents = this.loadedEvents.concat(result);
        }
        this.lastCount = result.length;
        this.currentPage = this.currentPage + 1;
        this.loading = false;
    }
}

export default EventListStore;