import './Footer.less'
import React, { Component, ChangeEvent } from 'react';
import { observer, inject } from 'mobx-react';
import { Col, Row, Input, Modal, Form, notification } from 'antd'
import logo from './../../assets/images/pth-icon-hd.png'
import SessionStore from '../../stores/SessionStore'
import { WindowSizeProps } from 'react-window-size';
import PthIcon from '../Icons';
import SocialLink from '../SocialLink/SocialLink';
import { Link } from 'react-router-dom';
import TextArea from 'antd/lib/input/TextArea';

export interface FooterProps extends WindowSizeProps {
    sessionStore?: SessionStore;
}

@inject('sessionStore')
@observer
class Footer extends Component<FooterProps> {

    constructor(props:FooterProps){
        super(props);
        this.partnerModalCancel = this.partnerModalCancel.bind(this);
        this.confirmPartnerRequest = this.confirmPartnerRequest.bind(this);
        this.showPartnerModal = this.showPartnerModal.bind(this);

        this.partnerFirstNameOnChange = this.partnerFirstNameOnChange.bind(this);
        this.partnerLastNameOnChange = this.partnerLastNameOnChange.bind(this);
        this.partnerEmailOnChange = this.partnerEmailOnChange.bind(this);
        this.partnerCompanyOnChange = this.partnerCompanyOnChange.bind(this);
        this.partnerPhoneOnChange = this.partnerPhoneOnChange.bind(this);
        this.partnerObservationsOnChange = this.partnerObservationsOnChange.bind(this);
        this.newsletterEmailOnChange = this.newsletterEmailOnChange.bind(this);
        this.subscribeNewsletter = this.subscribeNewsletter.bind(this);
    }

    partnerModalCancel() {
        this.props.sessionStore!.closePartnerModal();
    }

    async confirmPartnerRequest(){
        let response = await this.props.sessionStore!.postPartnerRequest();
        if (response.responseCode == 0) {
            notification.success({
                message: response.title,
                description: response.message
            });
            this.props.sessionStore!.closePartnerModal();
        }
        else {
            notification.error({
                message: response.title,
                description: response.message
            });
        }
    }

    showPartnerModal(){
        this.props.sessionStore!.openPartnerModal();
    }

    async partnerFirstNameOnChange(event: ChangeEvent<HTMLInputElement>) {
        this.props.sessionStore!.partnerFirstName = event.target.value;
    }

    async partnerLastNameOnChange(event: ChangeEvent<HTMLInputElement>) {
        this.props.sessionStore!.partnerLastName = event.target.value;
    }

    async partnerEmailOnChange(event: ChangeEvent<HTMLInputElement>) {
        this.props.sessionStore!.partnerEmail = event.target.value;
    }

    async partnerCompanyOnChange(event: ChangeEvent<HTMLInputElement>) {
        this.props.sessionStore!.partnerCompany = event.target.value;
    }

    async partnerPhoneOnChange(event: ChangeEvent<HTMLInputElement>) {
        this.props.sessionStore!.partnerPhone = event.target.value;
    }

    async partnerObservationsOnChange(event: ChangeEvent<HTMLTextAreaElement>) {
        this.props.sessionStore!.partnerObservations = event.target.value;
    }

    async newsletterEmailOnChange(event: ChangeEvent<HTMLInputElement>) {
        this.props.sessionStore!.newsletterEmail = event.target.value;
    }

    async subscribeNewsletter(){
        let response = await this.props.sessionStore!.postNewsletterRequest();
        if (response.responseCode == 0) {
            notification.success({
                message: response.title,
                description: response.message
            });
        }
        else {
            notification.error({
                message: response.title,
                description: response.message
            });
        }
    }

    public render() {
        return (
            <div>
                <div className="social-footer" style={{ padding: this.props.sessionStore!.isMobileMenu ? '10px 10px' : '10px 10%', textAlign: 'center' }}>
                    <Row type="flex" justify="center" align="middle">
                        <Col xs={24} sm={24} md={8} className="center-xs right-md" style={{ paddingRight: 5 }}>
                            <span className="footer-link footer-header">NEWSLETTER</span>
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                            <Input
                                className="newsletter-input"
                                placeholder="Enter email"
                                prefix={<PthIcon type="icon-mail" height="17" primaryColor="#a09e96" secondaryColor="#a09e96" />}
                                suffix={
                                    <a className="newsletter-button" onClick={this.subscribeNewsletter}>Send</a>
                                }
                                onChange={this.newsletterEmailOnChange}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={10}>
                            <SocialLink pthIconType="icon-twitter" backgroundColor="black" hoverColor="#8fcdef" url="https://twitter.com/premtickethub" />
                            <SocialLink pthIconType="icon-facebook" backgroundColor="black" hoverColor="#5a72b3" url="https://www.facebook.com/premiumtickethub" />
                            <SocialLink pthIconType="icon-instagram" backgroundColor="black" hoverColor="#5cb85c" url="https://www.instagram.com/premiumtickethub/" />
                        </Col>
                    </Row>
                </div>
                <div className="info-footer" style={{ padding: this.props.sessionStore!.isMobileMenu ? '10px 0' : '30px 10%' }}>
                    <Row type="flex" align="top">
                        <Col xs={24} sm={12} md={9} className="center-xs left-md" >
                            <div className="padding-md">
                                <img src={logo} width={45} />
                            </div>
                            <h3 className="footer-header-company">PREMIUM TICKETHUB</h3>
                            <p className="contact-info">+1.415.963.4370<br />Toll Free 888.FRNTROW<br />+52 (55) 46092299 En Español</p>
                        </Col>
                        <Col xs={24} sm={12} md={5} className="center-xs left-md">
                            <p className="footer-header">COMPANY</p>
                            <Link to='/about-us' className="footer-links">Overview</Link><br />
                            <a className="footer-links" onClick={this.showPartnerModal}>Partners</a><br />
                            <Link to='/privacy-policy' className="footer-links">Privacy policy</Link><br />
                            <Link to='/terms-and-conditions' className="footer-links">Terms &amp; Conditions</Link>
                        </Col>
                        <Col xs={24} sm={12} md={5} className="center-xs left-md">
                            <p className="footer-header">RESOURCES</p>
                            <Link className="footer-links" to="/articles">Articles</Link><br />
                            {/* <a className="footer-links" href="#Smallchat">LiveChat</a><br /> */}
                            <Link className="footer-links" to="/faqs">FAQ</Link><br />
                            {/* <a className="footer-links" href="#">Login</a><br />
                            <a className="footer-links" href="/Home/PTHEspanol">PTHEspañol</a> */}

                        </Col>
                        <Col xs={24} sm={12} md={5} className="center-xs left-md">
                            <p className="footer-header">WHAT'S HOT</p>
                            <Link to='/buy-tickets-for-category/La-Liga-Matches' className="footer-links">La Liga</Link><br />
                            <Link to='/buy-tickets-for-category/Premier-League-Matches' className="footer-links">Premiere League</Link><br />
                            <Link to='/buy-tickets-for-category/NBA' className="footer-links">NBA</Link><br />
                            <Link to='/buy-tickets-for-category/NHL' className="footer-links">NHL</Link><br />
                            <Link to='/buy-tickets-for-category/Champions-League-Matches' className="footer-links">Champions League</Link>
                        </Col>
                    </Row>
                </div>
                <Modal
                    title="BECOME A PARTNER"
                    visible={this.props.sessionStore!.partnerModalVisible}
                    onCancel={this.partnerModalCancel}
                    cancelText="Close"
                    onOk={this.confirmPartnerRequest}
                    okText="Send"
                >
                    <Row type='flex' align='middle' justify='start' style={{ marginBottom: "18px" }}>
                        <Col span={24}>
                            <h4 style={{ color: "#434f57" }}>With over 10 years in business, we have formed strong and fruitful alliances with other companies in the entertainment industry.</h4>
                            <h4 style={{ color: "#434f57" }}>If you would like to know more about our company and how we can work together, tell us a little more about yourself and we will be in touch very soon.</h4>
                        </Col>
                    </Row>
                    <Row type='flex' align='middle' justify='start' gutter={12}>
                        <Col>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: "30px" }} gutter={12}>
                        <Col xs={24} md={15}>
                            <Form.Item style={{ fontWeight: "bold" }} label="Full name">
                                <Input style={{ backgroundColor: "#f3f3f3", border: "none" , padding:"22px 16px"}} onChange={this.partnerFirstNameOnChange} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={9}>
                            <Form.Item style={{ fontWeight: "bold" }} label="Company">
                                <Input style={{ backgroundColor: "#f3f3f3", border: "none" , padding:"22px 16px"}} onChange={this.partnerCompanyOnChange} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item style={{ fontWeight: "bold" }} label="Email">
                                <Input style={{ backgroundColor: "#f3f3f3", border: "none" , padding:"22px 16px"}} onChange={this.partnerEmailOnChange} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item style={{ fontWeight: "bold" }} label="Phone">
                                <Input style={{ backgroundColor: "#f3f3f3", border: "none" , padding:"22px 16px"}} onChange={this.partnerPhoneOnChange} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item style={{ fontWeight: "bold" }} label="Message">
                                <TextArea style={{ backgroundColor: "#f3f3f3", border: "none" , padding:"22px 16px"}} onChange={this.partnerObservationsOnChange} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Modal>
                <div className="copyrigth-footer">
                    Copyrigth © {new Date().getFullYear()} Premium Tickethub All rigths reserved.
                </div>
            </div>
        )
    }
}

export default Footer;