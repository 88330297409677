import http from '../httpService'
import EventCategory from './dto/eventCategory'
import TEVOPinnedCategory from './dto/TEVOPinnedCategory';

class EventCategoryService{
    public async getFootballEventCategories(): Promise<EventCategory[]> {
        let result = await http.get('api/EventCategories/GetFootballEventCategories');
        return result.data;
      }

      public async getTEVOPinnedCategories(): Promise<TEVOPinnedCategory[]> {
        let result = await http.get('api/EventCategories/TEVOPinnedCategories');
        return result.data;
      }
}

export default new EventCategoryService();