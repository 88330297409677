import { action, observable } from 'mobx';
import EventCategory from './../services/eventCategories/dto/eventCategory'
import eventCategoryService from '../services/eventCategories/eventCategoryService';
import TEVOPinnedCategory from '../services/eventCategories/dto/TEVOPinnedCategory';
class EventCategoryStore{
    @observable footballEventCategories: EventCategory[]=[];
    @observable TEVOPinnedCategories: TEVOPinnedCategory[]=[];
   

    @action
    async getFootballEventCategories() {
        let result = await eventCategoryService.getFootballEventCategories();
        this.footballEventCategories = result;
      }
      @action
    async getTEVOPinnedCategories() {
        let result = await eventCategoryService.getTEVOPinnedCategories();
        this.TEVOPinnedCategories = result;
      }
}

export default EventCategoryStore;