//Common/Stores/SessionStore.js
import { observable, action } from 'mobx';
import EventSummary from './../services/events/dto/eventSummary'
import eventService from './../services/events/eventService';
import Faq from './../services/faqs/dto/faq'
import faqService from './../services/faqs/faqService';
import TicketGroup from '../services/events/dto/TicketGroup';
import CheckoutModel from '../services/events/dto/CheckoutModel'
import userRequestService from '../services/userRequest/userRequestService';
import userRequestResponse from '../services/userRequest/dto/userRequestResponse';
import OrderPostedResponse from '../services/events/dto/OrderPostedResponse';
import ConfirmCartModel from '../services/events/dto/ConfirmCartModel';
class SessionStore {

    @observable authUser = {}; //also handled in store
    @observable mobileMenuOpen = false; //to know when the drawer at left is opened or not
    @observable isMobileMenu = false; //to know when to enable mobile navigation
    @observable tab = 0;
    @observable isMobileSearchOpen = false; //to know when the search input at top of screen in mobile is opened or not
    @observable isSearching = false; //to enable or disbaled the search 4 dots spinner
    @observable isDesktopSearchResultOpen = false; //to display or hide the search result
    @observable isMobileSearchResultOpen = false;
    searchQuery: string = '';// current query applied in navigation search box
    @observable searchResult: EventSummary[] = [];
    @observable faqs: Faq[] = [];
    @observable checkingOut: boolean = false;

    @action
    async search() {
        let result = await eventService.searchEvents(this.searchQuery, 1, 5);
        this.searchResult = result;
        this.isSearching = false;
        this.isDesktopSearchResultOpen = true;
        this.isMobileSearchResultOpen = true;
    }

    @action
    setIsMobileMenu = (windowWidth: number) => {
        this.isMobileMenu = windowWidth <= 840;
    }

    @action
    toggleMobileMenuOpen = () => {
        if (!this.mobileMenuOpen) {
            if (this.isMobileMenu) {
                this.mobileMenuOpen = true;
            }
        } else {
            this.mobileMenuOpen = false;
        }
    }

    @action
    async getFaqs() {
        if (this.faqs.length > 0)
            return;
        let result = await faqService.getAll();
        this.faqs = result;
    }





    //Partners Request
    @observable partnerModalVisible: boolean = false;
    partnerFirstName: string = '';
    partnerLastName: string = '';
    partnerEmail: string = '';
    partnerCompany: string = '';
    partnerPhone: string = '';
    partnerObservations: string = '';

    @action
    closePartnerModal() {
        this.partnerModalVisible = false;
    }
    @action
    openPartnerModal() {
        this.partnerModalVisible = true;
    }

    async postPartnerRequest(): Promise<userRequestResponse> {
        return await userRequestService.postPartnerRequest(this.partnerFirstName, this.partnerLastName, this.partnerEmail, this.partnerCompany, this.partnerPhone, this.partnerObservations);
    }

    //Newsletter Request
    newsletterEmail: string = '';
    async postNewsletterRequest(): Promise<userRequestResponse> {
        return await userRequestService.postNewsletterRequest(this.newsletterEmail);
    }

    //Checkout
    @observable orderCode: string = 'No Purchase';
    @observable cartCount = 0; //current items cart count
    ticketInCart: TicketGroup = new TicketGroup();
    selectedQuantityIndex: number = 0;
    @observable checkoutModel: CheckoutModel = new CheckoutModel();
    @observable currentStep: number = 0;
    @observable shippingInfoEnable: boolean = true;
    @observable modalLoading:boolean = false;

    @action
    async confirmShoppingCart(ticketGroup: TicketGroup, selectedQuantityIndex: number) {
        this.modalLoading = true;
        this.ticketInCart = ticketGroup;
        this.selectedQuantityIndex = selectedQuantityIndex;
        this.cartCount = ticketGroup.options[selectedQuantityIndex];
        var model = await eventService.getCheckoutModel(this.ticketInCart.id, this.ticketInCart.ours);
        model.selectedTicketOption = selectedQuantityIndex;
        this.checkoutModel = model;
        sessionStorage.setItem('checkOutModel', JSON.stringify(model));
        this.result = undefined;
        this.currentStep = 0;
        this.modalLoading = false;
    }

    @action
    setTicketSelectedOption(value: number) {
        this.checkoutModel!.selectedTicketOption = value;
    }

    @action
    setStep(step: number) {
        if (step == 2) {
            var valid = this.validateSecondStep()
            if (valid) {
                this.currentStep = step;
            }
            return;
        }
        this.currentStep = step;
    }

    @action
    async readCheckoutModel() {
        if (sessionStorage.getItem('checkOutModel')) {
            this.checkoutModel = JSON.parse(sessionStorage.getItem('checkOutModel')!);
            this.cartCount = this.checkoutModel.options![this.checkoutModel.selectedTicketOption!];
        } else {
            this.cartCount = 0;
            this.result = undefined;
        }
    }

    @action
    emptyCart() {
        this.cartCount = 0;
        sessionStorage.removeItem('checkOutModel')
        this.ticketInCart = new TicketGroup();
        this.result = undefined;
    }

    @action
    shippingInfoRequired() {
        this.shippingInfoEnable = !this.shippingInfoEnable;
        this.validateSecondStep();
    }

    //Checkout Fields
    firstName: string = '';
    lastName: string = '';
    email: string = '';
    hotelName: string = '';
    phoneNumber: string = '';
    addressLine1: string = '';
    addressLine2: string = '';
    zipCode: string = '';
    city: string = '';
    state: string = '';
    country: string = 'AF';

    ccNumber: string = '';
    cvv: string = '';
    month: string = '';
    year: string = '';
    yearmonth: string = '';

    @action
    setFirstName(value: string) {
        this.firstName = value;
        this.validateSecondStep()
    }
    @action
    setLastName(value: string) {
        this.lastName = value;
        this.validateSecondStep()
    }
    @action
    setEmail(value: string) {
        this.email = value;
        this.validateSecondStep()
    }
    @action
    setPhone(value: string) {
        this.phoneNumber = value;
        this.validateSecondStep()
    }
    @action
    setHotelName(value: string) {
        this.hotelName = value;
        this.validateSecondStep()
    }
    @action
    setAddressLine1(value: string) {
        this.addressLine1 = value;
        this.validateSecondStep()
    }
    @action
    setAddressLine2(value: string) {
        this.addressLine2 = value;
        this.validateSecondStep()
    }
    @action
    setZipCode(value: string) {
        this.zipCode = value;
        this.validateSecondStep()
    }
    @action
    setCity(value: string) {
        this.city = value;
        this.validateSecondStep()
    }
    @action
    setShippingState(value: string) {
        this.state = value;
        this.validateSecondStep()
    }
    @action
    setCountry(value: string) {
        this.country = value;
        this.validateSecondStep()
    }

    @action
    setCCNumber(value: string) {
        this.ccNumber = value;
        this.validateThirdStep()
    }

    @action
    setCVV(value: string) {
        this.cvv = value;
        this.validateThirdStep()
    }

    @action
    setMonth(value: string) {
        this.month = value;
        this.validateThirdStep()
    }

    @action
    setYearMont(value: string) {
        this.yearmonth = value;
        this.validateThirdStep()
    }
    //checkoutValidation
    @observable firstNameValidation: string = '';
    @observable phoneValidation: string = '';
    @observable lastNameValidation: string = '';
    @observable emailValidation: string = '';
    @observable hotelNameValidation: string = '';
    @observable addressLine1Validation: string = '';
    @observable addressLine2Validation: string = '';
    @observable zipCodeValidation: string = '';
    @observable cityValidation: string = '';
    @observable stateValidation: string = '';
    @observable countryValidation: string = '';
    @observable ccNumberValidation: string = '';
    @observable cvvValidation: string = '';
    @observable monthValidation: string = '';
    @observable yearValidation: string = '';
    @observable yearmonthValidation: string = '';

    @action
    validateSecondStep(): boolean {
        var valid = true;
        if (this.isEmptyOrSpaces(this.firstName)) {
            valid = false;
            this.firstNameValidation = 'This field is required.';
        } else {
            this.firstNameValidation = '';
        }
        if (this.isEmptyOrSpaces(this.phoneNumber)) {
            valid = false;
            this.phoneValidation = 'This field is required.';
        } else {
            this.phoneValidation = '';
        }
        if (this.isEmptyOrSpaces(this.lastName)) {
            valid = false;
            this.lastNameValidation = 'This field is required.';
        } else {
            this.lastNameValidation = '';
        }
        if (this.isEmptyOrSpaces(this.email)) {
            valid = false;
            this.emailValidation = 'This field is required.';
        } else if (!this.isEmptyOrSpaces(this.email) && !this.validateEmail(this.email)) {
            valid = false;
            this.emailValidation = 'This field must be a valid email.';
        } else {
            this.emailValidation = '';
        }
        if (this.checkoutModel.ticketType == 0 && this.shippingInfoEnable
            && this.isEmptyOrSpaces(this.hotelName)) {
            valid = false;
            this.hotelNameValidation = 'This field is required.';
        } else {
            this.hotelNameValidation = '';
        }

        if (this.checkoutModel.ticketType != 1 && this.shippingInfoEnable
            && this.isEmptyOrSpaces(this.addressLine1)) {
            valid = false;
            this.addressLine1Validation = 'This field is required.';
        } else {
            this.addressLine1Validation = '';
        }
        if (this.checkoutModel.ticketType != 1 && this.shippingInfoEnable
            && this.isEmptyOrSpaces(this.addressLine2)) {
            valid = false;
            this.addressLine2Validation = 'This field is required.';
        } else {
            this.addressLine2Validation = '';
        }
        if (this.checkoutModel.ticketType != 1 && this.shippingInfoEnable
            && this.isEmptyOrSpaces(this.zipCode)) {
            valid = false;
            this.zipCodeValidation = 'This field is required.';
        } else {
            this.zipCodeValidation = '';
        }
        if (this.checkoutModel.ticketType != 1 && this.shippingInfoEnable
            && this.isEmptyOrSpaces(this.city)) {
            valid = false;
            this.cityValidation = 'This field is required.';
        } else {
            this.cityValidation = '';
        }
        if (this.checkoutModel.ticketType != 1 && this.shippingInfoEnable
            && this.isEmptyOrSpaces(this.state)) {
            valid = false;
            this.stateValidation = 'This field is required.';
        } else {
            this.stateValidation = '';
        }
        if (this.checkoutModel.ticketType != 1 && this.shippingInfoEnable
            && this.isEmptyOrSpaces(this.country)) {
            valid = false;
            this.countryValidation = 'This field is required.';
        } else {
            this.countryValidation = '';
        }
        if (!this.shippingInfoEnable) {
            this.hotelNameValidation = '';
            this.addressLine1Validation = '';
            this.addressLine2Validation = '';
            this.zipCodeValidation = '';
            this.cityValidation = '';
            this.stateValidation = '';
            this.countryValidation = '';
        }
        return valid;
    }

    validateThirdStep(): boolean {
        var valid = true;
        if (this.isEmptyOrSpaces(this.ccNumber)) {
            valid = false;
            this.ccNumberValidation = 'This field is required.';
        } else if (isNaN(Number(this.ccNumber)) || this.ccNumber.length < 12 || this.ccNumber.length > 20) {
            this.ccNumberValidation = 'This field must be a valid credit card.';
        } else {
            this.ccNumberValidation = '';
        }
        if (this.isEmptyOrSpaces(this.yearmonth)) {
            valid = false;
            this.yearmonthValidation = 'This field is required.';
        } else if (this.yearmonth.length != 5) {
            this.yearmonthValidation = 'This field must be in the format MM/YY';
        } else if (!this.validateYearMonth(this.yearmonth)) {
            this.yearmonthValidation = 'This field must be in the format MM/YY';
        }
        else {
            this.yearmonthValidation = '';
        }
        if (this.isEmptyOrSpaces(this.cvv)) {
            valid = false;
            this.cvvValidation = 'This field is required.';
        } else if ((this.cvv.length != 3 && this.cvv.length != 4) || isNaN(Number(this.cvv))) {
            valid = false;
            this.cvvValidation = 'CVV must have three or four digits.';
        } else {
            this.cvvValidation = '';
        }
        return valid;
    }

    validateYearMonth(str: string): boolean {
        if (!str.includes('/'))
            return false;
        var splitted = str.split('/', 2);
        if (splitted.length != 2 || isNaN(Number(splitted[0])) || isNaN(Number(splitted[1])) || Number(splitted[0]) < 1 || Number(splitted[0]) > 12 || Number(splitted[1]) < 20) return false;
        var date = new Date(2000 + Number(splitted[1]), Number(splitted[0]), 1);
        if (date < new Date())
            return false;
        return true;
    }

    isEmptyOrSpaces(str: string) {
        return str === null || str.match(/^\s*$/) !== null;
    }

    validateEmail(email: string) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    //CheckoutPost
    @observable orderConfirmed: boolean = false;
    result?: OrderPostedResponse;
    eventName?: string = '';
    @action
    async postOur(nonce: string): Promise<OrderPostedResponse> {
        this.checkingOut = true;
        var model = new ConfirmCartModel();
        model.ticketGroupId = this.checkoutModel.ticketGroupId!;
        model.quantity = this.checkoutModel.options![this.checkoutModel.selectedTicketOption!];
        model.firstName = this.firstName;
        model.lastName = this.lastName;
        model.email = this.email;
        model.phone = this.phoneNumber;
        model.addressLine1 = this.addressLine1;
        model.addressLine2 = this.addressLine2;
        model.city = this.city;
        model.state = this.state;
        model.zipcode = this.zipCode;
        model.country = this.country;
        model.info = this.hotelName;
        model.paymentNonce = nonce;
        model.our = true;
        model.pricePerTicket = this.checkoutModel.prices![this.checkoutModel.selectedTicketOption!] / model.quantity!;
        let result = await eventService.postOrder(model);
        if (result.success) {
            this.orderConfirmed = true;
            this.eventName = this.checkoutModel.eventName;
            this.emptyCart();
        }
        this.result = result;
        this.checkingOut = false;
        return result;
    }

    async postTE(): Promise<OrderPostedResponse> {
        this.checkingOut = true;
        var splitted = this.yearmonth.split('/', 2);
        var model = new ConfirmCartModel();
        model.ticketGroupId = this.checkoutModel.ticketGroupId!;
        model.quantity = this.checkoutModel.options![this.checkoutModel.selectedTicketOption!];
        model.firstName = this.firstName;
        model.lastName = this.lastName;
        model.email = this.email;
        model.phone = this.phoneNumber;
        model.addressLine1 = this.addressLine1;
        model.addressLine2 = this.addressLine2;
        model.city = this.city;
        model.state = this.state;
        model.zipcode = this.zipCode;
        model.country = this.country;
        model.ccnumber = this.ccNumber;
        model.cvv = this.cvv;
        model.month = splitted[0];
        model.year = splitted[1];
        model.our = false;
        model.pricePerTicket = this.checkoutModel.prices![this.checkoutModel.selectedTicketOption!] / model.quantity!;
        model.teInfo = this.checkoutModel.session! + ' - ' + this.checkoutModel.row!;
        let result = await eventService.postOrder(model);
        if (result.success) {
            this.emptyCart();
            this.orderConfirmed = true;
        }
        this.checkingOut = false;
        return result;
    }
}

export default SessionStore;