import axios from 'axios';

const qs = require('qs');

const http = axios.create({
  baseURL: 'https://api-pthnewwebsite.azurewebsites.net',//'https://localhost:5001/',//
  timeout: 15000,
  paramsSerializer: function(params) {
    return qs.stringify(params, {
      encode: false,
    });
  },
});


http.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    setTimeout(() => {}, 1000);
    return Promise.reject(error);
  }
);
export default http;