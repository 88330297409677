import http from "../httpService";
import AutocompleteModel from "./dto/autocompleteModel";

class AutocompleteService {
    public async getPerformerAutocomplete(query: string): Promise<AutocompleteModel[]> {
        let result = await http.get('api/Performers/GetAutocompleteOptions/' + query);
        return result.data;
    }

    public async getVenueAutocomplete(query: string): Promise<AutocompleteModel[]> {
        let result = await http.get('api/Venues/GetAutocompleteOptions/' + query);
        return result.data;
    }

    public async getCategoryAutocomplete(query: string): Promise<AutocompleteModel[]> {
        let result = await http.get('api/EventCategories/GetAutocompleteOptions/' + query);
        // console.log(result.data);
        return result.data;
    }

    public async getEventAutocomplete(query:string): Promise<AutocompleteModel[]> {
        let result = await http.get('api/Events/GetAutocompleteOptions/'+query);
        return result.data;
      }
}

export default new AutocompleteService();