import React, { Component } from 'react';
import PthIcon from '../Icons';

export interface ISocialLinkState {
    hover: boolean;
}
export interface SocialLinkProps {
    pthIconType: string;
    url: string;
    backgroundColor: string;
    hoverColor: string;
}

class SocialLink extends Component<SocialLinkProps,ISocialLinkState>{

    constructor(props: SocialLinkProps) {
        super(props);
        this.state = {hover:false};
        this.redirect = this.redirect.bind(this);
        this.toogleHover = this.toogleHover.bind(this);
    }

    toogleHover(){
        this.setState({hover: !this.state.hover})
    }

    redirect() {
        window.location.href = this.props.url;
    }
    public render() {
        var normalStyle = {backgroundColor:this.props.backgroundColor, border:"none", width: 45, height: 45, padding: "4px 8px",lineHeight: "1.33", borderRadius: 49, margin:"5px"};
        var hoveredStyle = {backgroundColor:this.props.hoverColor, cursor:"pointer", border:"none", width: 45, height: 45, padding: "4px 8px",lineHeight: "1.33", borderRadius: 49,  margin:"5px"};
        return <button type="button" style={this.state.hover?hoveredStyle:normalStyle} onClick={this.redirect} onMouseEnter={this.toogleHover} onMouseLeave={this.toogleHover}>
            <PthIcon type={this.props.pthIconType} primaryColor="white" secondaryColor="white" height="20"/>
        </button>
    }
}

export default SocialLink;