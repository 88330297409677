import { action, observable } from 'mobx';
import LandingCover from './../services/homeConfig/dto/landingCover'
import homeConfigService from './../services/homeConfig/homeConfigService';
import EventSummary from '../services/events/dto/eventSummary';
import SeasonalEventSettings from '../services/homeConfig/dto/seasonalEventSettings';
import Banner from '../services/events/dto/Banner';
class HomePageStore {
    @observable covers: LandingCover[] = [];
    @observable trendingEvents: EventSummary[]=[];
    @observable seasonalEvents: EventSummary[]=[];
    @observable otherEvents: EventSummary[]=[];
    @observable banners: Banner[]=[];
    @observable seasonalEventSettings:SeasonalEventSettings = new SeasonalEventSettings();

    @action
    async getLandingPageCovers() {
        if (this.covers.length > 0)
            return;
        let result = await homeConfigService.getCovers();
        this.covers = result;
    }

    @action
    async getTrendingEvents() {
        if (this.trendingEvents.length > 0)
            return;
        let result = await homeConfigService.getTrendingEvents();
        this.trendingEvents = result;
    }

    @action
    async getSeasonalEvents() {
        if (this.seasonalEvents.length > 0)
            return;
        let result = await homeConfigService.getSeasonalEvents();
        this.seasonalEvents = result;
    }


    @action
    async getSeasonalEventSettings() {
        if (this.seasonalEventSettings.icon)
            return;
        let result = await homeConfigService.getSeasonalEventSettings();
        this.seasonalEventSettings = result;
    }

    @action
    async getOtherEvents() {
        if (this.otherEvents.length > 0)
            return;
        let result = await homeConfigService.getOtherEvents();
        this.otherEvents = result;
    }

    @action
    async getBanners() {
        if (this.banners.length > 0)
            return;
        let result = await homeConfigService.getBanners();
        this.banners = result;
    }
}

export default HomePageStore;