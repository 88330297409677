import { action, observable } from 'mobx';
import eventService from './../services/events/eventService';
import AddToCartModel from '../services/events/dto/AddToCartModel';
import { toJS } from 'mobx';
import TicketGroup from '../services/events/dto/TicketGroup';
import userRequestResponse from '../services/userRequest/dto/userRequestResponse';
import userRequestService from '../services/userRequest/userRequestService';

class BookingStore {
  SeatMapURL: string = '';
  Category: string = '';
  EventName: string = '';
  EventDate: Date = new Date();
  Venue: string = '';
  VenueInfo: string = '';
  Location: string = '';
  HomeTeam: string = '';
  Awayteam: string = '';
  Type: string = '';
  Groups: TicketGroup[] = [];
  @observable loaded: boolean = false;
  @observable modalVisible: boolean = false;
  @observable selectedGroup: TicketGroup = new TicketGroup();
  @observable selectedQuantityIndex: number = 0;
  @observable totalPrice: number = 0;
 

  

  @action
  async getAddToCartModel(eventName: string, id:number) {
    let result = await eventService.getTicketsForEvent(eventName, id);
    this.SeatMapURL = result.seatMapURL!;
    this.Category = result.category!;
    this.EventName = result.eventName!;
    this.EventDate = result.eventDate!;
    this.Venue = result.venue!;
    this.VenueInfo = result.venueInfo!;
    this.Location = result.location!;
    this.HomeTeam = result.homeTeam!;
    this.Awayteam = result.awayteam!;
    this.Groups = result.groups!;
    this.loaded = true;
  }

  @action
  hideModal() {
    this.modalVisible = false;
    this.modalRequestVisible = false;
  }

  @action
  showModal(pos: number) {
    this.modalVisible = true;
    this.selectedGroup = this.Groups[pos];
    this.updateSelectedQuantity(0);
  }

  

  @action
  updateSelectedQuantity(pos: number) {
     this.totalPrice = this.selectedGroup.prices[pos];
     this.selectedQuantityIndex = pos;
  }


  //request price
  @observable modalRequestVisible: boolean = false;
  requestTicketGroupId:number = 0;
  requestPriceName:string = '';
  requestPriceLastName:string = '';
  requestPriceEmail:string = '';
  requestPriceQuantity:string = '';

  @action
  showModalRequest(id: number) {
    this.modalRequestVisible = true;
    this.requestTicketGroupId = id;
  }

  @action
  async postPriceRequest():Promise<userRequestResponse> {
    
    let result = await userRequestService.postPriceRequest(this.requestPriceName,this.requestPriceLastName,this.requestPriceEmail,this.requestPriceQuantity,this.requestTicketGroupId);
    
    return result;
  }


}
export default BookingStore;