import http from '../httpService'
import UserRequestResponse from './dto/userRequestResponse'

class UserRequestService {

  public async postCustomRequest(name: string, email: string, eventName: string, quantity: string): Promise<UserRequestResponse> {
    let result = await http.post('api/UserRequest/PostCustomRequest', { name: name, email: email, eventName: eventName, quantity: quantity });
    return result.data;
  }
  public async postPartnerRequest(firstName: string, lastName: string, email: string, company: string, phone: string, observations: string, ): Promise<UserRequestResponse> {
    let result = await http.post('api/PartnerRequest/PostRequest', { firstName: firstName, lastName: lastName, email: email, companyName: company, phone: phone, observations: observations });
    return result.data;
  }
  public async postNewsletterRequest(newsletterEmail: string): Promise<UserRequestResponse> {
    let result = await http.post('api/Newsletter/Subscribe/' + newsletterEmail);
    return result.data;
  }
  public async postPriceRequest(firstName:string, lastName:string, email: string, quantity:string, ticketId:number): Promise<UserRequestResponse> {
    let result = await http.post('api/PriceRequest/PostRequest', { name: firstName, lastName: lastName, email: email, quantity: quantity, ticketId:ticketId });
    return result.data;
  }
}

export default new UserRequestService();