export default class CheckoutModel {
    ticketGroupId?:number;
    session?: string;
    row?: string;
    categoryId?: number;
    price?: number;
    maxQuantity?: number;
    ticketType?: number;
    eventName?: string;
    eventCategory?: string;
    eventDate?: Date;
    deliveryNotes?: string;
    eventType?: string;
    selectedTicketOption?:number;
    options?:number[];
    prices?:number[];
    our?: boolean;
    braintreeClientToken?:string;
}